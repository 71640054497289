<template>
    <v-container>
        <v-row>
            <v-col cols="6">
                <v-card flat>
                    <v-card-title>
                        <v-icon>mdi-calendar-clock</v-icon>
                        <span>Gruppemøter</span>
                    </v-card-title>
                    <v-card-text v-if="aktiviteter.length" class="text--primary">
                        <p>Kommende gruppemøter:</p>
                        <activities-list :activities="aktiviteter" :base-route="'/sorg/gruppemoter'"> </activities-list>
                    </v-card-text>
                    <v-card-actions v-if="aktiviteter.length">
                        <v-btn to="/sorg/kalender" outlined text>Flere gruppemøter</v-btn>
                    </v-card-actions>
                </v-card>
            </v-col>
            <v-col cols="6" v-if="grupper || hasRole('ANSATT')">
                <v-card v-if="hasRole('ANSATT')" flat to="/sorg/grupper/">
                    <v-card-title>
                        <v-icon>mdi-calendar-edit</v-icon>
                        <span>Sorggrupper</span>
                    </v-card-title>
                    <v-card-text class="text--primary"> Opprett nye, rediger sorggruppper og se deltakere. </v-card-text>
                </v-card>
                <v-card v-if="mineGrupper.length > 0" flat>
                    <v-card-title>
                        <v-icon>mdi-calendar-clock</v-icon>
                        <span>Grupperleder for</span>
                    </v-card-title>
                    <v-card-text class="text--primary">
                        <router-link
                            v-for="(gruppe, index) in mineGrupper"
                            :key="index"
                            :to="routeToGroup(gruppe)"
                            v-bind:class="{ 'v-card__date': true, 'v-card__date--no-link': routeToGroup(gruppe) == '' }"
                        >
                            <div class="grey lighten-2">
                                <v-icon>mdi-account-multiple</v-icon>
                            </div>
                            <div>
                                <span v-html="gruppe.tittel" style="font-weight: bold"></span>
                                <span>Når: {{ gruppe.motetidspunkt }}</span>
                            </div>
                        </router-link>
                    </v-card-text>
                </v-card>
            </v-col>
        </v-row>
    </v-container>
</template>

<script>
import moment from 'moment';
import { mapActions, mapGetters } from 'vuex';
import ActivitiesList from '@/components/ActivitiesList.vue';

export default {
    name: 'ActivitiesPage',
    components: {
        ActivitiesList,
    },
    data() {
        return {
            aktiviteter: [],
            mineAktiviteter: [],

            grupper: [],
            mineGrupper: [],
        };
    },
    computed: {
        ...mapGetters('api', ['user', 'hasRole']),
    },
    filters: {
        format(value, format) {
            if (value) {
                return value.format(format);
            }
            return '';
        },
    },
    created: async function () {
        await this.update();
    },

    methods: {
        ...mapActions('api', ['request', 'formatActivity']),

        /**
         * update
         */
        update: async function () {
            // alle sorggrupper
            let response = await this.request({
                method: 'get',
                url: '/sorggruppe',
            });

            if (response && Array.isArray(response)) {
                this.grupper = [];
                for (const gruppe of response) {
                    this.grupper.push(gruppe);

                    if (gruppe.deltakere) {
                        for (const deltaker of gruppe.deltakere) {
                            if (!deltaker.fjernet && deltaker.person.id == this.user.id) {
                                this.mineGrupper.push(gruppe);
                                break;
                            }
                        }
                    }

                    if (gruppe.ledere) {
                        for (const leder of gruppe.ledere) {
                            if (!leder.fjernet && leder.person.id == this.user.id) {
                                this.mineGrupper.push(gruppe);
                                break;
                            }
                        }
                    }
                }
            }

            // alle gruppemøter
            const today = moment().set({ hour: 0, minute: 0, second: 0, millisecond: 0 });

            response = await this.request({
                method: 'get',
                url: '/aktivitet/aktivitet-type/SORGGRUPPE',
                params: { from: today.format('DD.MM.YYYY HH:mm') },
            });

            if (response && Array.isArray(response)) {
                this.aktiviteter = [];

                for (let aktivitet of response) {
                    aktivitet = await this.formatActivity(aktivitet);
                    if (aktivitet.type.id == 'SORGGRUPPE') {
                        this.aktiviteter.push(aktivitet);

                        if (aktivitet.deltakere) {
                            for (const deltaker of aktivitet.deltakere) {
                                if (!deltaker.fjernet && deltaker.person.id == this.user.id) {
                                    this.mineAktiviteter.push(aktivitet);
                                    break;
                                }
                            }
                        }

                        if (aktivitet.referanseId) {
                            for (const gruppe of this.mineGrupper) {
                                if (gruppe.id == aktivitet.referanseId) {
                                    this.mineAktiviteter.push(aktivitet);
                                    console.log(gruppe);
                                    break;
                                }
                            }
                        }
                    }
                }
            }

            // ikke ansatt, se kun egne gruppemøter
            if (!this.hasRole('ANSATT')) {
                this.aktiviteter = this.mineAktiviteter;
            }

            // vis maks 5
            this.aktiviteter = this.aktiviteter.slice(0, 8);
        },

        routeToGroup: function (gruppe) {
            if (this.hasRole('ANSATT') || this.hasRole('FRIVILLIG_SORG')) {
                return '/sorg/gruppe/' + gruppe.id;
            } else {
                return '';
            }
        },
    },
};
</script>
